// LogoutButton.js
import React from 'react';
import { useHistory } from 'react-router-dom';
import { logout } from '../auth';

const LogoutButton = () => {
    const history = useHistory();

    const handleLogout = async () => {
        await logout();
        history.push('/');
    };

    return (
        <a href="/" onClick={(e) => {
            e.preventDefault();
            handleLogout();
        }} className="nav-link red">
            <i className="icon ion-md-power"/>
            <span>Çıkış Yap</span>
        </a>
    );
};

export default LogoutButton;
