import React, {useState, useRef} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
    const {t} = useTranslation();
    const {register, formState: {errors}} = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage('');

        try {
            let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/saveContact`, {
                method: 'POST',
                body: JSON.stringify({
                    name: e.target.name.value,
                    email: e.target.email.value,
                    message: e.target.message.value,
                }),
                headers: {
                    'Content-Type': "application/json",
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            setMessage(t("contact-success-message"));
        } catch (error) {
            console.error(error);
            setMessage(t("contact-error-message"));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form className="mb-5" onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="name">Ad Soyad:</label>
                <input {...register('name', {required: true})} type="text" className="form-control" id="name"/>
                {errors.name && <span>{t("contact-required-message")}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="email">E-posta:</label>
                <input {...register('email', {required: true})} type="email" className="form-control" id="email"/>
                {errors.email && <span>{t("contact-required-message")}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="message">Mesaj:</label>
                <textarea {...register('message', {required: true})} className="form-control" id="message"/>
                {errors.message && <span>{t("contact-required-message")}</span>}
            </div>
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                onChange={(token) => setRecaptchaToken(token)}
            />
            <button type="submit" className="btn btn-primary mt-2" disabled={!recaptchaToken || isLoading}>
                {isLoading ? 'Gönderiliyor...' : 'Gönder'}
            </button>
            {message && <div>{message}</div>}
        </form>
    );
};

export default ContactForm;
