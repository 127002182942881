import React from 'react';
import { useTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";

export default function AboutUs() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Lira Döviz | {t("html-title-about-us")}</title>
                <meta name="description" content={t("html-description")} />
            </Helmet>
        <div className="page-content about-us">
            <div className="container">
                <h1 className="text-center" dangerouslySetInnerHTML={{__html: t("company-title")}}/>
                <div className="col-md-12 mt-3">
                    <p className="mt-1" dangerouslySetInnerHTML={{__html: t("about-us-p1")}}/>
                    <ul dangerouslySetInnerHTML={{ __html: t("about-us-l1") }}>
                    </ul>
                </div>
                <div className="col-md-12 d-flex mt-3 align-items-center">
                    <img
                        alt="hakkimizda-resim-1"
                        src={'img/exchange-live-chart.jpg'}
                        className="img-fluid"
                    />
                </div>
            </div>
        </div>
        </>
    );
}
