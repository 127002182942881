import React from 'react';
import CompanyIntro from '../components/CompanyIntro';
import MarketsList from '../components/MarketsList';
import Converter from '../components/Converter';
import { useTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";

export default function Home() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Lira Döviz | {t("html-title-home")}</title>
                <meta name="description" content={t("html-description")} />
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {/*<MarketCarousel />*/}
                    </div>
                    <div className="col-md-12">
                        <CompanyIntro/>
                    </div>
                    <div className="col-md-8">
                        {/*<MarketsListForeks/>*/}
                    </div>
                    <div className="col-md-8">
                        <MarketsList/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Converter/>
                    </div>
                </div>
            </div>
        </>
    );
}
