import React from 'react';
import MarketCarousel from '../components/MarketCarousel';
import MarketsList from '../components/MarketsList';
import { useTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";
import TradingViewWidget from "../components/TradingViewWidget";
import { ThemeConsumer } from "../context/ThemeContext";

export default function Markets() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Lira Döviz | {t("html-title-price-info")}</title>
                <meta name="description" content={t("html-description")} />
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <MarketCarousel/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {/*<MarketCarouselForeks/>*/}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {/*<MarketsListForeks/>*/}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <MarketsList/>
                    </div>
                </div>
                <div className="mb-4">
                    <ThemeConsumer>
                        {({ data }) => (
                            <TradingViewWidget theme={data.theme} />
                        )}
                    </ThemeConsumer>
                </div>
            </div>
        </>
    );
}
