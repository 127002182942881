import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/ionicons.min.css';
import './assets/scss/style.scss';
import './localization';
import './assets/css/custom.css';
import './assets/css/tablet.css';
import './assets/css/mobile.css';
import TagManager from 'react-gtm-module'
import axios from "axios";

axios.defaults.withCredentials = true;

const tagManagerArgs = {
    gtmId: 'GTM-TDB8VK2B'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

