// src/auth.js
import axios from 'axios';
import React from "react";

export const getToken = () => {
    return localStorage.getItem('token');
};

export const isUserLoggedIn = () => {
    return getToken() != null;
};

export const setToken = (token) => {
    localStorage.setItem('token', token);
};

export const fetchCurrentUserData = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getCurrentUser`,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': getToken(),
                },
                credentials: "include",
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
};

export const logout = async () => {
    try {
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/logout`,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': getToken(),
                },
                credentials: "include",
            });
        localStorage.removeItem('token');

    } catch (error) {
        console.error('Logout error:', error);
    }
};
