import React, { Component } from 'react';
import NavbarHome from "./NavbarHome";
export default class Header extends Component {
    componentDidMount() {
        let el = document.querySelector('#darkTheme');
        if (el) {
            el.addEventListener('click', function () {
                document.body.classList.toggle('dark');
            });
        }
    }
    render() {
        return (
            <>
                <NavbarHome />
            </>
        );
    }
}
