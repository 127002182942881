// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react';
import { useTranslation } from "react-i18next";

function TradingViewWidget({ theme }) {
    const container = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = JSON.stringify({
            "symbols": [
                ["FOREXCOM:USDTRY|1D"],
                ["FOREXCOM:EURTRY|1D"],
                ["CAPITALCOM:GBPTRY|1D"],
                ["CAPITALCOM:CHFTRY|1D"],
                ["CAPITALCOM:AUDTRY|1D"],
                ["CAPITALCOM:CADTRY|1D"],
                ["FX_IDC:AEDTRY|1D"],
                ["FX_IDC:JPYTRY|1D"]
            ],
            "chartOnly": false,
            "width": 1000,
            "height": 500,
            "locale": "tr",
            "colorTheme": theme === 'light' ? "light" : "dark",
            "autosize": false,
            "showVolume": false,
            "showMA": false,
            "hideDateRanges": false,
            "hideMarketStatus": false,
            "hideSymbolLogo": true,
            "scalePosition": "right",
            "scaleMode": "Normal",
            "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
            "fontSize": "10",
            "noTimeScale": false,
            "valuesTracking": "1",
            "changeMode": "price-and-percent",
            "chartType": "area",
            "maLineColor": "#2962FF",
            "maLineWidth": 1,
            "maLength": 9,
            "lineWidth": 2,
            "lineType": 0,
            "dateRanges": [
                "1d|1",
                "1m|30",
                "3m|60",
                "12m|1D",
                "60m|1W",
                "all|1M"
            ]
        });
        container.current.innerHTML = '';
        container.current.appendChild(script);
    }, [theme]);

    return (
        <div className="row-tradingview-widget">
            <div className="tradingview-widget-container" ref={container}>
                <div className="tradingview-widget-container__widget"/>
            </div>
            <div className="tradingview-widget-copyright">
                <a href="https://tradingview.com/" rel="noopener nofollow" target="_blank">
                    <span className="blue-text">{t("trading-view-disclaimer")}</span>
                </a>
            </div>
        </div>
    );
}
export default TradingViewWidget;
