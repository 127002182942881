import React, { useEffect, useState } from 'react';
import {Button, Dropdown, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link, useLocation } from "react-router-dom";
import {ThemeConsumer} from "../context/ThemeContext";
import {fetchCurrentUserData, isUserLoggedIn} from '../auth';
import LogoutButton from './LogoutButton';

const NavbarHome = () => {
    const location = useLocation();

    const [userCurrentData, setCurrentUserData] = useState(null);

    useEffect(() => {
        const getCurrentUserData = async () => {
            const data = await fetchCurrentUserData();
            setCurrentUserData(data);
        };
        getCurrentUserData();
    }, []);

    return (
        <header className="light-bb">
            <Navbar expand="lg">
                <Link className="navbar-brand" to="/">
                    <ThemeConsumer>
                        {({ data }) => {
                            return data.theme === 'light' ? (
                                <img src={'img/logo-dark.svg'} className="d-none" alt="logo" />
                            ) : (
                                <img src={'img/logo-light.svg'} className="d-none" alt="logo" />
                            );
                        }}
                    </ThemeConsumer>
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="navbar-nav mr-auto">
                        <Link to="/" className="nav-link">
                            LİRA DÖVİZ
                        </Link>
                        <Link to="/" className="nav-link">
                            Ana Sayfa
                        </Link>
                        <Link to="/hakkimizda" className="nav-link">
                            Hakkımızda
                        </Link>
                        <Link to="/iletisim" className="nav-link">
                            İletişim
                        </Link>
                        <Link to="/genel-bilgiler" className="nav-link">
                            Genel Bilgiler
                        </Link>
                        <Link to="/canli-piyasalar" className="nav-link">
                            Canlı Piyasa
                        </Link>
                        {/*<Link to="/admin" className="nav-link">
                            Giriş Ekranı
                        </Link>
                        <Link to="/signup" className="nav-link">
                            Kayıt Ekranı
                        </Link>
                        <NavDropdown title="Yetkili Kullanıcı">
                            <Link to="/fiyat-guncelle" className="dropdown-item">
                                Fiyat Güncelle
                            </Link>
                            <Link to="/ayarlar" className="dropdown-item">
                                Ayarlar
                            </Link>
                            <Link to="/" className="dropdown-item">
                                Çıkış yap
                            </Link>
                        </NavDropdown>*/}
                    </Nav>
                    <Nav className="navbar-nav ml-auto">
                        <Dropdown className="header-custom-icon">
                            <ThemeConsumer>
                                {({ data, update }) => (
                                    <Button variant="default" onClick={update} id="darkTheme">
                                        {data.theme === 'light' ? (
                                            <i className="icon ion-md-moon"/>
                                        ) : (
                                            <i className="icon ion-md-sunny"/>
                                        )}
                                    </Button>
                                )}
                            </ThemeConsumer>
                        </Dropdown>
                        {/*   <Dropdown className="header-custom-icon">
                             <Dropdown.Toggle variant="default">
                                <i className="icon ion-md-notifications"></i>
                                <span className="circle-pulse"></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div className="dropdown-header d-flex align-items-center justify-content-between">
                                    <p className="mb-0 font-weight-medium">
                                        6 New Notifications
                                    </p>
                                    <a href="#!" className="text-muted">
                                        Clear all
                                    </a>
                                </div>
                                <div className="dropdown-body">
                                    <a href="#!" className="dropdown-item">
                                        <div className="icon">
                                            <i className="icon ion-md-lock"></i>
                                        </div>
                                        <div className="content">
                                            <p>Account password change</p>
                                            <p className="sub-text text-muted">5 sec ago</p>
                                        </div>
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                        <div className="icon">
                                            <i className="icon ion-md-alert"></i>
                                        </div>
                                        <div className="content">
                                            <p>Solve the security issue</p>
                                            <p className="sub-text text-muted">10 min ago</p>
                                        </div>
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                        <div className="icon">
                                            <i className="icon ion-logo-android"></i>
                                        </div>
                                        <div className="content">
                                            <p>Download android app</p>
                                            <p className="sub-text text-muted">1 hrs ago</p>
                                        </div>
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                        <div className="icon">
                                            <i className="icon ion-logo-bitcoin"></i>
                                        </div>
                                        <div className="content">
                                            <p>Bitcoin price is high now</p>
                                            <p className="sub-text text-muted">2 hrs ago</p>
                                        </div>
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                        <div className="icon">
                                            <i className="icon ion-logo-usd"></i>
                                        </div>
                                        <div className="content">
                                            <p>Payment completed</p>
                                            <p className="sub-text text-muted">4 hrs ago</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="dropdown-footer d-flex align-items-center justify-content-center">
                                    <a href="#!">View all</a>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>*/}
                        {isUserLoggedIn() && (
                            <Dropdown className="header-img-icon">
                                <Dropdown.Toggle variant="default">
                                    <img src={'img/avatar.svg'} alt="avatar" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className="dropdown-header d-flex flex-column align-items-center">
                                        <div className="figure mb-3">
                                            <img src={'img/avatar.svg'} alt="" />
                                        </div>
                                        <div className="info text-center">
                                            <p className="name font-weight-bold mb-0">
                                                {userCurrentData && userCurrentData.firstname} {userCurrentData && userCurrentData.lastname}
                                            </p>
                                            <p className="email text-muted mb-3">
                                                {userCurrentData && userCurrentData.email}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="dropdown-body">
                                        <ul className="profile-nav">
                                            {/*  <li className="nav-item">
                                            <Link to="/profile" className="nav-link">
                                                <i className="icon ion-md-person"></i>
                                                <span>Profile</span>
                                            </Link>
                                        </li>*/}
                                            <li className="nav-item">
                                                <Link to="/fiyat-guncelle" className="nav-link">
                                                    <i className="icon ion-md-wallet"/>
                                                    <span>Fiyat Güncelle</span>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/ayarlar" className="nav-link">
                                                    <i className="icon ion-md-settings"/>
                                                    <span>Ayarlar</span>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <LogoutButton />
                                            </li>
                                        </ul>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
};

export default NavbarHome;
