import React from 'react';
import {useTranslation} from 'react-i18next';
import {Helmet} from "react-helmet";

export default function Information() {
    const {t} = useTranslation();

    return (
        <>
            <Helmet>
                <title>Lira Döviz | {t("html-title-information")}</title>
                <meta name="description" content={t("html-description")} />
            </Helmet>
            <div className="information-section mt-3 mb-3">
                <div className="container">

                    <div id="accordion1">
                        <div className="card">
                            <div className="card-header-information" id="headingOne">
                                <h1 className="mb-0" style={{ fontSize: '1.25rem' }}>
                                    <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                        {t("information-security-title")}
                                    </button>
                                </h1>
                            </div>

                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                 data-parent="#accordion">
                                <div className="card-body">
                                    <ul dangerouslySetInnerHTML={{__html: t("information-security-list")}}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="accordion2">
                        <div className="card">
                            <div className="card-header-information" id="headingOne">
                                <h2 className="mb-0" style={{ fontSize: '1.25rem' }}>
                                    <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                        {t("fake-money-title")}
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                 data-parent="#accordion">
                                <div className="card-body" dangerouslySetInnerHTML={{__html: t("fake-money-content")}}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
