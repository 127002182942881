import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import { setToken } from '../auth';

export default function Login() {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    async function submit(e) {
        e.preventDefault();

        try {
            const uri = `${process.env.REACT_APP_BACKEND_URL}/login`;
            const body = {
                email,
                password,
            };
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                },
            };

            const response = await axios.post(uri, body, config);
            const { data } = response;

            if (data.token) {
                console.log('Login success:', data);
                setToken(data.token);
                setShowSuccessAlert(true);
                setTimeout(() => {
                    setShowSuccessAlert(false);
                    history.push('/');
                }, 2000);
            } else {
                setLoginError('Girdiğiniz şifre hatalıdır. Lütfen tekrar deneyiniz.');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        } catch (error) {
            console.error('Login error:', error);
            setLoginError('Giriş yapılırken bir hata oluştu.');
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }

    return (
        <>
            <div className="vh-100 d-flex justify-content-center">
                <div className="form-access my-auto">
                    <form onSubmit={submit}>
                        <span>Giriş</span>
                        <div className="form-group">
                            <input
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                className="form-control"
                                placeholder="E-Posta Adresi"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                className="form-control"
                                placeholder="Şifre"
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Giriş
                        </button>
                        {/*<div className="text-right mt-2">
                            <Link to="/reset">Forgot Password?</Link>
                        </div>*/}
                    </form>
                    {loginError && (
                        <Alert variant="danger" className="mt-2">
                            <Alert.Heading>Hata!!</Alert.Heading>
                            <p>Girdiğiniz şifre hatalıdır. Lütfen tekrar deneyiniz.</p>
                        </Alert>
                    )}

                    {showSuccessAlert && (
                        <Alert variant="success" className="mt-2">
                            <Alert.Heading>Başarılı</Alert.Heading>
                            <p>Başarılı bir giriş yaptınız</p>
                        </Alert>
                    )}
                </div>
            </div>
        </>
    );
}
