import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import Index from './pages';
import './localization';
// import { WebSocketProvider } from "./components/WebSocketContextForeksData";
export default class App extends Component {
    state = {
        theme: localStorage.getItem('theme') || 'light'
    };

    componentDidMount() {
        document.body.classList.toggle('dark', this.state.theme === 'dark');
    }

    render() {
        return (
            <>
                <BrowserRouter>
                    {/*<WebSocketProvider url={process.env.REACT_APP_FOREKS_URL}>*/}
                        <Route component={ScrollToTop} />
                        <ThemeProvider
                            value={{
                                data: this.state,
                                update: () => {
                                    this.setState((state) => {
                                        const newTheme = state.theme === 'light' ? 'dark' : 'light';
                                        localStorage.setItem('theme', newTheme);
                                        document.body.classList.toggle('dark', newTheme === 'dark');
                                        return { theme: newTheme };
                                    });
                                },
                            }}
                        >
                            <Index />
                        </ThemeProvider>
                    {/*</WebSocketProvider>*/}
                </BrowserRouter>
            </>
        );
    }
}

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};
