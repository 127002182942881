import React, { useEffect, useState } from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import { UseGetMarketData } from './UseGetMarketData';
import axios from "axios";

const formatUpdateDate = (rawDate) => {
    const date = new Date(rawDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

const formatGoldName = (name) => {
    return name
        .replace('gram-altin', 'Gram Altın')
        .replace('ceyrek-altin', 'Çeyrek Altın')
        .replace('yarim-altin', 'Yarım Altın')
        .replace('tam-altin', 'Tam Altın')
        .replace('cumhuriyet-altini', 'Cumhuriyet Altını')
        .replace('ata-altin', 'Ata Altın')
        .replace('14-ayar-altin', '14 Ayar Altın')
        .replace('18-ayar-altin', '18 Ayar Altın')
        .replace('22-ayar-bilezik', '22 Ayar Bilezik')
        .replace('ikibucuk-altin', '2.5 Altın')
        .replace('besli-altin', 'Beşli Altın')
        .replace('gremse-altin', 'Gremse Altın')
        .replace('resat-altin', 'Reşat Altın')
        .replace('hamit-altin', 'Hamit Altın')
        .replace('ons', 'ONS')
        .replace('gumus', 'Gümüş');
};

export default function MarketsList() {
    const {t} = useTranslation();
    const { currencyData } = UseGetMarketData(); // Use the currencyData prop
    const [updatedPrices, setUpdatedPrices] = useState({});

    useEffect(() => {
        const fetchUpdatedPrices = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getPrices`);
                const prices = response.data.reduce((acc, item) => {
                    if (!acc[item.key]) {
                        acc[item.key] = { alis: null, satis: null };
                    }
                    if (item.priceFlag === 0) {
                        acc[item.key].alis = parseFloat(item.priceSet);
                    } else if (item.priceFlag === 1) {
                        acc[item.key].satis = parseFloat(item.priceSet);
                    }
                    return acc;
                }, {});
                setUpdatedPrices(prices);
            } catch (error) {
                console.error('Güncellenmiş fiyatlar çekilirken bir hata oluştu:', error);
            }
        };

        fetchUpdatedPrices();
    }, []);

    const renderCurrencyData = (currencyType) => {
        /*if (!currencyData) return null;

        const filteredData = Object.keys(currencyData)
            .filter((key) => currencyData[key]?.Tür === currencyType)
            .map((key) => {
                const { Alış, Satış, Değişim } = currencyData[key];
                const changeValue = parseFloat(Değişim.replace('%', '').replace(',', '.'));

                let changeClass = 'black';
                if (changeValue > 0) {
                    changeClass = 'green';
                } else if (changeValue < 0) {
                    changeClass = 'red';
                }

                return (
                    <tr key={key}>
                        <td>{formatGoldName(key)}</td>
                        <td>{Alış}</td>
                        <td>{Satış}</td>
                        <td className={changeClass}>{Değişim}</td>
                    </tr>
                );
            });

        return filteredData;*/


        if (!currencyData) return null;

        const favorites = ["USD","EUR","GBP","CHF","AUD","CAD","AED","JPY"];

        return favorites.map((key) => {
            if (!currencyData[key]) return null;

            const currency = currencyData[key];
            const updatedPrice = updatedPrices[key] || {};
            const alis = updatedPrice.alis ? (parseFloat(currency["Alış"].replace(',', '.')) + updatedPrice.alis).toFixed(4).toString().replace('.', ',') : currency["Alış"];
            const satis = updatedPrice.satis ? (parseFloat(currency["Satış"].replace(',', '.')) + updatedPrice.satis).toFixed(4).toString().replace('.', ',') : currency["Satış"];

            const formattedKey = formatGoldName(key);
            const changeValue = parseFloat(currency["Değişim"].replace('%', '').replace(',', '.'));

            let changeClass = 'black';
            let directionIcon = <i className="ion-md-arrow-round-forward" style={{color: '#18214D'}}/>;
            if (changeValue > 0) {
                changeClass = 'green';
                directionIcon = <i className="icon ion-md-arrow-up" style={{color: '#26de81'}}/>;
            } else if (changeValue < 0) {
                changeClass = 'red';
                directionIcon = <i className="icon ion-md-arrow-down" style={{color: '#ff231f'}}/>;
            }

            return (
                <tr key={key}>
                    <td>{formattedKey}TRY</td>
                    <td>{alis}</td>
                    <td>{satis}</td>
                    <td style={{fontSize: '28px' }}>{directionIcon}</td>
                    <td className={changeClass}>{currency["Değişim"]}</td>
                </tr>
            );
        });
    };

    const renderUpdateDate = () => {
        if (!currencyData || !currencyData.Update_Date) return null;

        const formattedDate = formatUpdateDate(currencyData.Update_Date);

        return (
            <div className="update-date">
                {t('market-table-last-update')}: {formattedDate}
            </div>
        );
    };

    const renderFavorites = () => {
        if (!currencyData) return null;

        const favorites = ['USD', 'EUR', 'gremse-altin'];

        return favorites.map((key) => {
            if (!currencyData[key]) return null;

            const { Alış, Satış, Değişim } = currencyData[key];
            const formattedKey = formatGoldName(key);
            const changeValue = parseFloat(Değişim.replace('%', '').replace(',', '.'));

            let changeClass = 'black';
            if (changeValue > 0) {
                changeClass = 'green';
            } else if (changeValue < 0) {
                changeClass = 'red';
            }

            return (
                <tr key={key}>
                    <td>
                        <i className="icon ion-md-star"></i> {formattedKey}
                    </td>
                    <td>{Alış}</td>
                    <td>{Satış}</td>
                    <td className={changeClass}>{Değişim}</td>
                </tr>
            );
        });
    };


    return (
        <>
            <div className="markets pb70">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <div className="markets-pair-list">
                                <Tabs defaultActiveKey="doviz">
                                    <Tab eventKey="star" tabClassName="d-none" title="★ Favoriler">
                                            <div className="table-responsive">
                                                <table className="table star-active">
                                                    <thead>
                                                    <tr>
                                                        <th>{renderUpdateDate()}</th>
                                                        <th>Alış</th>
                                                        <th>Satış</th>
                                                        <th>Değişim</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {renderFavorites()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Tab>
                                    <Tab eventKey="doviz" title="Döviz Fiyatları">
                                        <div className="table-responsive">
                                            <table className="table">
                                               <thead>
                                                <tr>
                                                    <th>{renderUpdateDate()}</th>
                                                    <th>{t('market-table-buy')}</th>
                                                    <th>{t('market-table-sell')}</th>
                                                    <th>{t('market-table-direction')}</th>
                                                    <th>{t('market-table-diff')}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {renderCurrencyData('Döviz')}
                                                </tbody>
                                            </table>


                                        </div>
                                    </Tab>
                                    <Tab eventKey="altin" tabClassName="d-none" title="Altın Fiyatları">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th>{renderUpdateDate()}</th>
                                                    <th>{t("market-table-buy")}</th>
                                                    <th>{t("market-table-sell")}</th>
                                                    <th>{t("market-table-diff")}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {renderCurrencyData('Altın')}
                                                </tbody>

                                            </table>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
