import React from 'react';
import SetPrice from '../components/SetPrice';
import { useTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";

export default function SetPricePage() {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>Lira Döviz | {t("html-title-set-price")}</title>
                <meta name="description" content={t("html-description")} />
            </Helmet>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <SetPrice />
                    </div>
                </div>
            </div>
        </>
    );
}
