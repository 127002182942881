import { useState, useEffect } from 'react';

export function UseGetMarketData() {
    const [currencyData, setCurrencyData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://finans.truncgil.com/today.json');
                const data = await response.json();
                setCurrencyData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 600000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return { currencyData, setCurrencyData };
}
