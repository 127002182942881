import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationTR from './localization/tr.json'; // Path to your translation file

const resources = {
    tr: {
        translation: translationTR
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'tr',
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;