import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import {Switch, Route, Redirect, useHistory } from 'react-router-dom';
import Home from './home';
import Exchange from './exchange';
import Markets from './markets';
import Profile from './profile';
import Settings from './settings';
import Login from './login';
import Signup from './signup';
import Lock from './lock';
import AboutUs from './about-us';
import Contact from './contact';
import Information from './information';
import Notfound from './notfound';
import SetPrice from "./set-price";
import { isUserLoggedIn } from '../auth';

const ProtectedRoute = ({ children, redirectTo }) => {
    const history = useHistory();

    useEffect(() => {
        if (!isUserLoggedIn()) {
            history.push(redirectTo);
        }
    }, [history, redirectTo]);

    return isUserLoggedIn() ? children : null;
};

export default function index() {
    return (
        <>
            <Layout>
                <Switch>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route path="/hakkimizda">
                        <AboutUs/>
                    </Route>
                    <Route path="/iletisim">
                        <Contact/>
                    </Route>
                    <Route path="/genel-bilgiler">
                        <Information/>
                    </Route>
                    <Route path="/admin">
                        {isUserLoggedIn() ? <Redirect to="/" /> : <Login />}
                    </Route>
                    {/*<Route path="/signup">
                        <Signup />
                    </Route>*/}
                    <Route path="/canli-piyasalar">
                        <Markets/>
                    </Route>
                    <Route path="/fiyat-guncelle">
                        <ProtectedRoute redirectTo="/">
                            <SetPrice />
                        </ProtectedRoute>
                    </Route>
                    <Route path="/ayarlar">
                        <ProtectedRoute redirectTo="/">
                            <Settings />
                        </ProtectedRoute>
                    </Route>
                    <Route path="/notfound">
                        <Notfound/>
                    </Route>
                    {/*
                    <Route path="/kilit-ac">
                        <Lock/>
                    </Route>
                    <Route path="/trader-ekran">
                        <Exchange />
                    </Route>
                    */}
                </Switch>
            </Layout>
        </>
    );
}
