import React from 'react';
import {useTranslation} from "react-i18next";
import Maps from '../components/Maps';
import ContactForm from "../components/ContactForm";
import {Helmet} from "react-helmet";

export default function Contact() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Lira Döviz | {t("html-title-contact-us")}</title>
                <meta name="description" content={t("html-description")} />
            </Helmet>
            <div className="google-map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d188.15748090877378!2d28.9674199!3d41.0138848!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab99301eefa1b%3A0x44b0c6dd0e43e7bc!2zTWVyY2FuLCBVenVuIMOHYXLFn8SxIENkLiBObzo1NiwgMzQxMTYgRmF0aWkvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1716631390970!5m2!1str!2str"
                    width="100%"
                    height="400"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                />

            </div>
            <div className="our-addresses">
                <div className="container mt-5">
                    <div className="col-md-12 d-none">
                        <h3 className="text-center">{t("contact-h1")}</h3>
                    </div>
                    <div className="col-md-12 mb-4">
                        <div className="card">
                            <div className="card-body d-flex flex-column">
                                <h1 className="card-title" style={{ fontSize: '1.25rem' }} dangerouslySetInnerHTML={{__html: t("company-title")}}/>
                                <h2 className="card-text" style={{ fontSize: '14px' }}>{t("contact-p1-1")}</h2>
                                <div className="row">
                                    <div className="col">
                                        <a href="https://www.google.com.tr/maps/place/Mercan,+Uzun+%C3%87ar%C5%9F%C4%B1+Cd.+No:56,+34116+Fatih%2F%C4%B0stanbul/@41.0138848,28.9674199,21z/data=!4m6!3m5!1s0x14cab99301eefa1b:0x44b0c6dd0e43e7bc!8m2!3d41.0138616!4d28.9675145!16s%2Fg%2F11cs7rqp4y?hl=tr&entry=ttu" target="_blank" rel="noreferrer" className="btn btn-primary btn-block">
                                            {t("contact-a1-1")}
                                        </a>
                                        <a href={t("contact-a1-2-html")} className="btn btn-danger btn-block">
                                            {t("contact-a1-2")}
                                        </a>
                                        <a href="mailto:bilgi@liradoviz.com" className="btn btn-success btn-block">
                                            {t("contact-a1-3")}
                                        </a>
                                        <a href="mailto:liradoviz@hs03.kep.tr" className="btn btn-info btn-block">
                                            {t("contact-a1-4")}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="container">
                    <ContactForm />
                </div>
            </div>
        </>
    );
}
