import React, { useEffect, useState } from 'react';
import {Tabs, Tab, Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UseGetMarketData } from './UseGetMarketData';
import {getToken} from "../auth";

export default function SetPrice() {
    const { t } = useTranslation();
    const { currencyData, setCurrencyData } = UseGetMarketData(); // Get currencyData from UseGetMarketData
    const [inputAmounts, setInputAmounts] = useState({});

    const updateCurrency = async (key, addedAmount, isBuy) => {
        try {
            const parsedAmount = parseFloat(addedAmount);
            const priceFlag = isBuy ? 0 : 1;
            const data = {
                priceSet: parsedAmount,
                priceFlag: priceFlag,
                key: key
            };

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/updatePrice`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': getToken(),
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                console.log('Veri başarıyla güncellendi.');
            } else {
                console.log('Veri güncelleme hatası:', response.statusText);
            }
        } catch (error) {
            console.error('Veri güncelleme hatası:', error);
        }

        /*const updatedData = { ...currencyData };
        const current = isBuy ? 'Alış' : 'Satış';
        const currentValue = parseFloat(updatedData[key][current].replace(',', '.'));
        const newAmount = currentValue + parseFloat(addedAmount);
        updatedData[key][current] = newAmount.toFixed(4).toString().replace('.', ',');

        setCurrencyData(updatedData);
        setInputAmounts({ ...inputAmounts, [key]: '0' });*/
    };

    const handleAmountChange = (key, event) => {
        const { value } = event.target;
        const validInput = /^-?\d*\.?\d*$/.test(value);

        if (validInput || value === '') {
            if (value === '0') {
                const updatedAmounts = { ...inputAmounts };
                console.log(updatedAmounts);
                delete updatedAmounts[key];
                setInputAmounts(updatedAmounts);
            } else {
                setInputAmounts({ ...inputAmounts, [key]: value });
            }
        }
    };

    const renderCurrencyData = () => {
        if (!currencyData) return null;

        const currencyRows = Object.keys(currencyData)
            .filter((key) => currencyData[key]?.Tür === 'Döviz')
            .map((key) => {
                const { Alış, Satış } = currencyData[key];

                return (
                    <tr key={key}>
                        <td>{key}</td>
                        <td>{Alış}</td>
                        <td>{Satış}</td>
                        <td>
                            <input
                                type="text"
                                value={inputAmounts[key] || 0}
                                onChange={(e) => handleAmountChange(key, e)}
                            />
                            <Button variant="primary" size={"sm"} className={"d-block mt-2"} onClick={() => updateCurrency(key, inputAmounts[key], true)}>Alış Ekle</Button>
                            <Button variant="primary" size={"sm"} className={"d-block mt-2"} onClick={() => updateCurrency(key, inputAmounts[key], false)}>Satış Ekle</Button>
                        </td>
                    </tr>
                );
            });

        return currencyRows;
    };

    return (
        <>
            <div className="markets pb70">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="markets-pair-list">
                                <Tabs defaultActiveKey="doviz">
                                    <Tab eventKey="doviz" title="Döviz Fiyatları">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>{t('market-table-buy')}</th>
                                                    <th>{t('market-table-sell')}</th>
                                                    <th>{t('set-price-table-add')}</th>
                                                </tr>
                                                </thead>
                                                <tbody>{renderCurrencyData()}</tbody>
                                            </table>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
