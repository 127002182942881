import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import {useTranslation} from "react-i18next";
import { UseGetMarketData } from './UseGetMarketData';
import axios from "axios"; // Import the hoo

export default function MarketCarousel() {
    const {t} = useTranslation();
    const { currencyData } = UseGetMarketData(); // Use the currencyData prop

    const [updatedPrices, setUpdatedPrices] = useState({});

    useEffect(() => {
        const fetchUpdatedPrices = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getPrices`);
                const prices = response.data.reduce((acc, item) => {
                    if (!acc[item.key]) {
                        acc[item.key] = { alis: null, satis: null };
                    }
                    if (item.priceFlag === 0) {
                        acc[item.key].alis = parseFloat(item.priceSet);
                    } else if (item.priceFlag === 1) {
                        acc[item.key].satis = parseFloat(item.priceSet);
                    }
                    return acc;
                }, {});
                setUpdatedPrices(prices);
            } catch (error) {
                console.error('Güncellenmiş fiyatlar çekilirken bir hata oluştu:', error);
            }
        };

        fetchUpdatedPrices();
    }, []);


    const renderMarketItems = () => {
        if (!currencyData) return null;

        const favorites = ["USD", "EUR", "GBP", "CHF", "AUD", "CAD", "AED", "JPY"];

        return favorites.map((key) => {
            const currencyItem = currencyData[key];
            if (!currencyItem) return null;

            const currency = currencyData[key];
            const updatedPrice = updatedPrices[key] || {};
            const alis = updatedPrice.alis ? (parseFloat(currency["Alış"].replace(',', '.')) + updatedPrice.alis).toFixed(4).toString().replace('.', ',') : currency["Alış"];
            const satis = updatedPrice.satis ? (parseFloat(currency["Satış"].replace(',', '.')) + updatedPrice.satis).toFixed(4).toString().replace('.', ',') : currency["Satış"];

            const changeValue = parseFloat(currency["Değişim"].replace('%', '').replace(',', '.'));

            let changeClass = 'black';
            if (changeValue > 0) {
                changeClass = 'green';
            } else if (changeValue < 0) {
                changeClass = 'red';
            }

            return (
                <div className="market-carousel-item" key={key}>
                    <div className="market-carousel-item-name">
                        <strong>{key}TRY</strong>
                    </div>
                    <h2>{t('market-table-buy')}: {alis}</h2>
                    <h2>{t('market-table-sell')}: {satis}</h2>
                    <h2>{t('market-table-diff')}: <span className={changeClass}>%{currency["Değişim"]}</span></h2>
                </div>
            );
        });
    };


    const settings = {
        infinite: true,
        speed: 2000,
        autoplay: true,
        autoplaySpeed: 1,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="market-carousel">
            <Slider {...settings}>
                {renderMarketItems()}
            </Slider>
        </div>
    );
}
