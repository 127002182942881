import React, { useState, useEffect } from 'react';
import { Container, Form, InputGroup, Table, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { UseGetMarketData } from './UseGetMarketData';

const ConverterCurrency = () => {
    const [amount, setAmount] = useState(0);
    const [fromCurrency, setFromCurrency] = useState('TRY');
    const [toCurrency, setToCurrency] = useState('USD');
    const [convertedAmount, setConvertedAmount] = useState(0);
    const { currencyData } = UseGetMarketData();
    const { t } = useTranslation();

    // console.log("ConverterCurrency marketData: ", marketData);

    const supportedCurrencies = ["USD", "EUR", "GBP", "CHF", "AUD", "CAD", "AED", "JPY"];
    const currencyOptions = ['TRY', ...supportedCurrencies];

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    };

    const handleFromCurrencyChange = (event) => {
        setFromCurrency(event.target.value);
    };

    const handleToCurrencyChange = (event) => {
        setToCurrency(event.target.value);
    };

    const findCurrencyRate = (currency) => {
        if (currency === 'TRY') return 1;
        const currencyInfo = currencyData && currencyData[currency];
        return currencyInfo ? parseFloat(currencyInfo.Alış.replace(',', '.')) : 1;
    };

    const convertCurrency = () => {
        if (amount > 0) {
            let converted;
            if (fromCurrency === 'TRY') {
                const toRate = findCurrencyRate(toCurrency);
                converted = amount / toRate;
            } else {
                const fromRate = findCurrencyRate(fromCurrency);
                const toRate = findCurrencyRate(toCurrency);
                converted = (amount / toRate) * fromRate;
            }
            setConvertedAmount(converted.toFixed(2));
        } else {
            alert(t("convert-currency-message"));
        }
    };

    return (
        <Container>
            <Form>
                <Form.Group controlId="amount">
                    <InputGroup className="mt-4">
                        <InputGroup.Prepend>
                            <InputGroup.Text className='inprice'>{t("currency-amount")}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type="number"
                            value={amount}
                            onChange={handleAmountChange}
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="fromCurrency">
                    <Form.Label>{t("current-currency")}</Form.Label>
                    <Form.Control
                        as="select"
                        value={fromCurrency}
                        onChange={handleFromCurrencyChange}
                    >
                        {currencyOptions.map((currency, index) => (
                            <option key={index} value={currency}>
                                {currency}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="toCurrency">
                    <Form.Label>{t("target-currency")}</Form.Label>
                    <Form.Control
                        as="select"
                        value={toCurrency}
                        onChange={handleToCurrencyChange}
                    >
                        {currencyOptions.map((currency, index) => (
                            <option key={index} value={currency}>
                                {currency}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Button variant="primary" onClick={convertCurrency}>{t("convert")}</Button>{' '}
                </Form.Group>
            </Form>
            <Table striped bordered className="mb-4">
                <thead>
                <tr>
                    <th>{t("current-currency")}</th>
                    <th>{t("target-currency")}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{amount} {fromCurrency}</td>
                    <td>{convertedAmount} {toCurrency}</td>
                </tr>
                </tbody>
            </Table>
        </Container>
    );
};

export default ConverterCurrency;
