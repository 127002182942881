import React from 'react';
import {useTranslation} from "react-i18next";

export default function CompanyIntro() {
    const {t} = useTranslation();

    return (
        <div className="company-intro">
            <div className="market-carousel-item">
                <h1 className="text-center mb-lg-5 mt-lg-5 pb-lg-5 pt-lg-5" dangerouslySetInnerHTML={{ __html: t("company-intro-text") }}></h1>
            </div>
        </div>
    );
}
