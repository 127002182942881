import React from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import ConverterGold from "./ConverterGold";
import ConverterCurrency from "./ConverterCurrency";

const Converter = () => {

    return (
        <div className="container-fluid">
            <div className="converter">
                <Tabs defaultActiveKey="doviz">
                    <Tab eventKey="doviz" title="Döviz Hesaplama">
                        <ConverterCurrency />
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};

export default Converter;
